var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Удаление магазина",
      "actions": _vm.actions
    }
  }, [_vm._v(" Вы действительно хотите удалить магазин"), _c('br'), _vm._v(" \"" + _vm._s(_vm.connection.name) + "\"? ")]);
};
var staticRenderFns = [];
export { render, staticRenderFns };