var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Редактирование магазина",
      "width": 420,
      "actions": _vm.actions
    }
  }, [_c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Название")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_connection.name,
      expression: "new_connection.name"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Название магазина",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.new_connection.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_connection, "name", $event.target.value);
      }
    }
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };