var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "lists-page"
  }, [_c('WarningAllLK'), _c('div', [!_vm.hasTokens && !_vm.$store.state.prerender && (!_vm.$store.state.user.user || _vm.$store.state.connections.loaded) ? _c('div', {
    staticClass: "lists-page__none"
  }, [_c('div', {
    staticClass: "lists-page__none-title"
  }, [_vm._v("Мои магазины")]), _c('div', {
    staticClass: "lists-page__none-text"
  }, [_vm._v(" Подключите ваш магазин на Wildberries или OZON к SalesFinder и получите доступ к расширенной аналитике ваших товаров и продаж. Для подключения вам понадобится API ключ ")]), _c('button', {
    staticClass: "btn-outline btn-width-auto",
    on: {
      "click": _vm.onCreateConnection
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/plus.svg`)
    }
  }), _vm._v(" Подключить магазин ")])]) : _vm._e(), _vm.hasTokens ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasTokens,
      expression: "hasTokens"
    }],
    staticClass: "lists-page__content"
  }, [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('button', {
          staticClass: "btn-outline btn-width-auto",
          on: {
            "click": _vm.onCreateConnection
          }
        }, [_c('img', {
          attrs: {
            "src": require(`@/assets/images/icons/plus.svg`)
          }
        }), _vm._v(" Подключить новый магазин ")])];
      },
      proxy: true
    }], null, false, 2093370484)
  }, [_vm._v(" Подключенные магазины ")]), _vm.dataCollectionTokens > 0 ? _c('badge', {
    staticClass: "badge",
    attrs: {
      "color": 'green',
      "icon": require('@/assets/images/icons/info.svg')
    },
    scopedSlots: _vm._u([{
      key: "text",
      fn: function () {
        return [_vm._v(" Сейчас мы загружаем данные по " + _vm._s(_vm.dataCollectionTokens > 1 ? "вашим магазинам" : "вашему магазину") + ". Когда загрузка завершится, вам придет уведомление на e-mail ")];
      },
      proxy: true
    }], null, false, 2804115290)
  }) : _vm._e(), _c('connections-table', {
    key: _vm.tableKey,
    staticClass: "mt32",
    attrs: {
      "hasItems": _vm.hasTokens
    },
    on: {
      "edit": _vm.onEdit,
      "remove": _vm.onRemove,
      "update:hasItems": function ($event) {
        _vm.hasTokens = $event;
      },
      "update:has-items": function ($event) {
        _vm.hasTokens = $event;
      }
    }
  })], 1) : _vm._e(), _c('modal-add-connection', {
    ref: "modalAddConnection",
    on: {
      "complete": _vm.onAdded
    }
  }), _c('modal-update-connection', {
    ref: "modalUpdateConnection"
  }), _c('modal-remove-connection', {
    ref: "modalRemoveConnection"
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };